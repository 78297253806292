@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Montserrat";
  color: white;
}

div {
  box-sizing: border-box;
}

span {
  color:rgb(58, 58, 58);
  display:inline-block;
  word-wrap: break-word;
}

html, body {
  min-height: 100%;
}
body {
  background-repeat: no-repeat;
}

p {
  margin: 16px auto;
  line-height: 1.5em;
}

h2 {
  font-size: 50px;
}

h3 {
  font-size: 30px;
}

/* layout & nav */
.layout {
 /* max-width: 1200px;  */
  margin: 0 auto;
}
nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  height: 50px;
  padding: 10px;
  position: absolute;
  z-index: 200;
  padding-left: 35px;
  padding-right: 35px;
}

nav .links {
  display: inline-block;
  text-align: right;
  justify-content: center;
  display:none;
}

nav h1 {
    display: flex;
    align-items: center;
    color: #fd3d60;
}

nav a {
  display: inline-block;
  margin-left: 20px;
  font-weight: 400;
  padding-bottom: 8px;
  border-bottom: 3px solid transparent;
}
nav a:hover {
  border-color: white;
}
footer p{
  text-align: center;
  color: #bbb;
  margin: 40px auto;
}
.rightContent {
    display:flex;
    justify-content: center;
    margin:auto;
}

.leftContent {
   margin-left: 40px;
}    

.header {
  position: relative;
  background: radial-gradient(at top left, rgb(214, 234, 247) 0%, rgb(20, 120, 202) 100%);
}

.headerplacement {
  height: 50px;
  width: 100%;
}


.headerContent {
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  align-items: center;
  position: relative;
  display: flex;
  max-width: 1000px;
  margin:auto;
}


#computer {
    transform-origin: center bottom;
    animation: showComputer 1.5s ease-in, bounceComputer 4s 1.5s infinite;
  }
  #shadow {
    animation: showShadow 2s, scaleShadow 4s 1.5s infinite;
  }
  #hole {
    transform-origin: center center;
    animation: scaleHoleUp .6s, scaleHoleDown 1s 1.5s;
    -webkit-animation-fill-mode: forwards;
  }
  #code-cover-screen {
    animation: scrollCodeIn 3s 2s;
  -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  #scrolling-code {
    animation: scrollCode 3s 3.5s linear infinite;
  }
  #scrolling-code-2 {
    animation: scrollCode2 3s 3.5s linear infinite;
    transform: translateY(87px);
  }
  
  @keyframes showComputer {
    0% {
      transform: translateY(500px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes showShadow {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes scaleHoleUp {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes scaleHoleDown {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes bounceComputer {
    0%, 100% {
      animation-timing-function: ease-in-out;
      transform: translateY(0);
    }
    18% {
      animation-timing-function: ease-in-out;
      transform: translateY(-20px);
    }
    36% {
      animation-timing-function: ease-in-out;
      transform: translateY(6px);
    }
    52% {
      animation-timing-function: ease-in-out;
      transform: translateY(-8px);
    }
    68% {
      animation-timing-function: ease-in-out;
      transform: translateY(2px);
    }
    80% {
      animation-timing-function: ease-in-out;
      transform: translateY(-4px);
    }
  }
  @keyframes scaleShadow {
    0%, 100% {
      animation-timing-function: ease-in-out;
      transform: scale(1);
    }
    18% {
      animation-timing-function: ease-in-out;
      transform: scale(1.05);
    }
    36% {
      animation-timing-function: ease-in-out;
      transform: scale(1.01);
    }
    53% {
      animation-timing-function: ease-in-out;
      transform: scale(1.04);
    }
    68% {
      animation-timing-function: ease-in-out;
      transform: scale(1.02);
    }
    80% {
      transform: scale(1.03);
    }
  }
  @keyframes scrollCodeIn {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(180px);
      opacity: 1;
    }
  }
  @keyframes scrollCode {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    49.99% {
      transform: translateY(-80px);
      opacity: 1;
    }
    50% {
      transform: translatey(87px);
      opacity: 0;
    }
    50.01% {
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes scrollCode2 {
    0% {
      transform: translateY(87);
      opacity: 1;
    }
    49.99% {
      transform: translateY(0);
      opacity: 1;
    }
    99.98% {
      transform: translatey(-80px);
      opacity: 1;
    }
    99.99% {
      transform: translateY(87);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .custom-shape-divider-bottom-1638699797 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1638699797 svg {
    position: relative;
    display: block;
    width: calc(153% + 1.3px);
    height: 89px;
}

.custom-shape-divider-bottom-1638699797 .shape-fill {
    fill: #FFFFFF;
}

.btmhead {
  height:100px;
  width:100%;
  background: rgba(255, 0, 0, 0);
}








.header h2 {
  font-size: 4em;
}
.header h3 {
  font-size: 3em;
  font-weight: 400;
  margin-bottom: 20px;
}
.btn {
  display: inline-block;
  background: #D42990;
  padding: 10px 16px;
  border-radius: 10px;
  margin-top: 20px;
  font-weight: 500;
}


#computer {
  transform-origin: center bottom;
  animation: showComputer 1.5s ease-in, bounceComputer 4s 1.5s infinite;
}
#shadow {
  animation: showShadow 2s, scaleShadow 4s 1.5s infinite;
}
#hole {
  transform-origin: center center;
  animation: scaleHoleUp .6s, scaleHoleDown 1s 1.5s;
  -webkit-animation-fill-mode: forwards;
}
#code-cover-screen {
  animation: scrollCodeIn 3s 2s;
-webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
#scrolling-code {
  animation: scrollCode 3s 3.5s linear infinite;
}
#scrolling-code-2 {
  animation: scrollCode2 3s 3.5s linear infinite;
  transform: translateY(87px);
}

@keyframes showComputer {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes showShadow {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scaleHoleUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scaleHoleDown {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes bounceComputer {
  0%, 100% {
    animation-timing-function: ease-in-out;
    transform: translateY(0);
  }
  18% {
    animation-timing-function: ease-in-out;
    transform: translateY(-20px);
  }
  36% {
    animation-timing-function: ease-in-out;
    transform: translateY(6px);
  }
  52% {
    animation-timing-function: ease-in-out;
    transform: translateY(-8px);
  }
  68% {
    animation-timing-function: ease-in-out;
    transform: translateY(2px);
  }
  80% {
    animation-timing-function: ease-in-out;
    transform: translateY(-4px);
  }
}
@keyframes scaleShadow {
  0%, 100% {
    animation-timing-function: ease-in-out;
    transform: scale(1);
  }
  18% {
    animation-timing-function: ease-in-out;
    transform: scale(1.05);
  }
  36% {
    animation-timing-function: ease-in-out;
    transform: scale(1.01);
  }
  53% {
    animation-timing-function: ease-in-out;
    transform: scale(1.04);
  }
  68% {
    animation-timing-function: ease-in-out;
    transform: scale(1.02);
  }
  80% {
    transform: scale(1.03);
  }
}
@keyframes scrollCodeIn {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(180px);
    opacity: 1;
  }
}
@keyframes scrollCode {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  49.99% {
    transform: translateY(-80px);
    opacity: 1;
  }
  50% {
    transform: translatey(87px);
    opacity: 0;
  }
  50.01% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes scrollCode2 {
  0% {
    transform: translateY(87);
    opacity: 1;
  }
  49.99% {
    transform: translateY(0);
    opacity: 1;
  }
  99.98% {
    transform: translatey(-80px);
    opacity: 1;
  }
  99.99% {
    transform: translateY(87);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.portfolio {
  text-align: center;
}
.portfolio > h2 {
  font-size: 3em;
  margin-top: 80px;
}
.portfolio > h3 {
  font-size: 2em;
  font-weight: 400;
}

footer {
  background: rgb(26 26 26 / 97%);
  width: 100%;
  bottom: 0px;
  position: relative;
}


.custom-shape-divider-top-1638703033 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  margin-top: -2px;
}

.custom-shape-divider-top-1638703033 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 35px;
}

.custom-shape-divider-top-1638703033 .shape-fill {

  fill: #FFFFFF;
}

.copyrightText {
  text-align: center;
}


.footerContent {
  height:200px;
  width: 70%;
  margin: auto;
  display: flex;
  align-items: center;
  text-align:center;
  justify-content: center;
}


.midsection h4 {
  color: grey;
  text-align: center;
}

.midcontent {
  height: 400px;
  width: 100%;
  display: flex;
  margin:auto;
  width:fit-content;
  align-items: center;
}



.midsection {
  height:550px;
  background-color: white;
  display:flex;
  justify-content: center;
  vertical-align:middle;
  align-items: center;
}

.card_content {
  padding:20px;
}

.sectionone {
  height: fit-content;
}

.circle_container {
  width: 100%;
  height:100px;
  display: flex;
  align-items: center;
}

.circle_container .circle {
  height: 80px;
  width: 80px;
  background:whitesmoke;
  border-radius: 100%;
  margin:auto;
}  




.cards {
  height: 300px;
  width:200px;
  border-radius:1em;
  display:flex;
  vertical-align:center;
  align-items: center;
  margin: 20px; 
  color: black;
  text-align: center;
  transition: all .5s;
  display:flex;
  flex-direction: column;
  overflow:hidden;
  -webkit-box-shadow: 0px 0px 19px -4px rgba(12,121,148,0.1); 
  box-shadow: 0px 0px 19px -4px rgba(12,121,148,0.1);
  font-family: "Montserrat";
  font-weight: bold;
  color: rgb(173, 172, 172);
  font-size: 12px;
}

.cards:hover {
  transform: scale(1.2);
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 19px -4px rgba(111, 218, 245, 0.75); 
  box-shadow: 0px 0px 19px -4px rgba(111, 218, 245, 0.75);
  color:rgb(82, 81, 81);
  background:rgb(249, 249, 252);
}

.codelogo {
  margin-top: 7px;
}


.codelogo path {
  color:rgb(217, 217, 252);
}

.cards:hover .codelogo path {
  color:rgb(28, 146, 243);
  transition: all 1s;
}

.boxlogo {
  margin-top: 7px;
}

.boxlogo path {
  color:rgba(243, 147, 3, 0.241);
}

.cards:hover .boxlogo path {
  color:rgba(243, 147, 3, 0.932);
  transition: all 1s;
}


.link {
  transform: scale(0.7);
}


.link path {
  color:rgba(55, 225, 63, 0.152);
}

.cards:hover .link path {
  color:rgba(55, 225, 63, 0.842);
  transition: all 1s;
}

.cards:hover .circle {
  border: 2px solid rgb(235, 235, 235);
  transition: all .2s;
}


.card_content{
  color: grey;
  margin-top: 20px;
  font-weight: 400;
}



.svg {
  transform: scale(0.8);
  transition: all 1s;
  color:green!important;
}


.cards:hover .svg {
  transform: scale(1.3);
}




@media only screen and (max-width: 800px) {
  .headerContent{
    flex-direction: column-reverse;
    }
}


@media only screen and (max-width: 1000px) {
  .midcontent {
    flex-direction: column;
    }
  .rightContent svg {
    width: 300px;
    height: 300px;
    margin-top: 20px;
  }
  .leftContent {
    margin-left: 20px;
}
.headerContent {
   grid-gap: 10px;
}
}



.App .openModalBtn {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: cornflowerblue;
  color: white;
  cursor: pointer;
}

.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgb(54 54 54 / 62%);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  z-index: 220;
  
}



.modalContainer {
  width: 500px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}


.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: black;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}


.emailForm button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

.modalContainer h4 {
  color:black;
}

.emailForm {
  display: flex;
  color: black;
  flex-direction: column;
  width: 80%;
}

.emailForm input {
  margin: 5px;
  color: black;
  background:rgb(255, 255, 255);
  border-radius: .4em;
  padding: 5px;
  border: 3px solid  rgb(212, 210, 210);
}

.messageInput {
  height: 150px;
}










